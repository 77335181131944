import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  components: {
    Close: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/Close'),
    User: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/User'),
    G2Academy: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/G2Academy'),
    Exit: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/Exit'),
    GlobeOutline: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/GlobeOutline'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion')
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    ...mapGetters('common', ['isNavBottomOpen']),
    ...mapGetters('student', ['isLeaveDetails', 'isRecallLeaveRequest'])
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'toggleNavBottom']),
    ...mapActions('student', ['toggleLeaveDetails', 'toggleRecallLeaveRequest']),
    toggle() {
      this.toggleNavBottom()
    },
    showDetails() {
      this.toggle()
      this.toggleLeaveDetails({ value: !this.isLeaveDetails })
    },
    showLeaveRequest() {
      this.toggle()
      this.toggleRecallLeaveRequest({ value: !this.isRecallLeaveRequest })
    }
  }
}
